<template>
    <div class="express">
        <full-page :options="options" id="fullpage2">
            <div class="section bg">
                <div class="section-all">
                    <div class="express__kj animate__animated animate__fadeInUp">
                        
                    </div>
                    <div class="express__font animate__animated animate__fadeInUp">
                        
                        <span class="size64">语音的士/的士之家</span>
                        <span class="size36">自由工作·更高收入</span>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="express-title">
                        <span class="size36">关于我们</span>
                        <span class="size16">About Us</span>
                    </div>
                    <div class="vo-about">
                        <div class="vo-about__view">
                            <div class="vo-about__view-content">
                                <div class="vo-about__img" :class="voiceClassleft">
                                    <img src="../../../static/images/vo/about.png" />
                                </div>
                                <div class="vo-about__font" :class="voiceClassright">
                                    <span class="size28">关于语音的士</span>
                                    <p class="size16">
                                        语音的士，是结合老年人对智慧手机操作的种种不便，对打车进行简化操作、优化流程、深化体验，构建便捷高效的老年人数字出行生活生态模式。其中，以出租车为例，作为大众公共交通重要载具，深入到各年龄层段人们的生活之中，
                                        将出租车数字化服务进行革新，是对老年人数字化生活关爱和倚重的重要途径和举措。
                                        所以语音的士应运而生。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="all-title">
                        <span class="size36">我们的产品</span>
                        <span class="size16">Product</span>
                    </div>
                    <div class="express-bocontent">
                        <div class="express-menu"
                        :class="[expreIndex == 0 ? '' : 'express-menu__ac']">
                            <div class="express-menu__li"
                            :class="[expreIndex == 0 ? 'express-menu__active' : '']"
                            @click="expreTap(0)">语音的士</div>
                            <div class="express-menu__li"
                            :class="[expreIndex == 1 ? 'express-menu__active' : '']"
                            @click="expreTap(1)">的士之家</div>
                        </div>
                        <div class="express-swiper">
                            <div class="express-swiper__content" :class="swiperRight">
                                <swiper class="swiper" :options="swiperOption"
                                ref="Myswiper"
                                @slideChange="changeswiper"
                                v-if="swiperShow">
                                    <swiper-slide v-for="(item,index) in ckList"
                                    :key="index+'p'">
                                    <div class="express-swiper__left">
                                        <span class="express-swiper__h">{{item.id}}</span>
                                        <div class="express-swiper__con">
                                            <h2 class="size28">{{item.title}}</h2>
                                            <p class="size16">
                                                {{item.memo}}
                                            </p>
                                        </div>
                                    </div>
                                    </swiper-slide>
                                </swiper>
                                <div class="express-swiper__naction">
                                    <div class="swiper-container-prev swiper-container__na">
                                        <img src="../../../static/images/home/swiper-left.png" />
                                    </div>
                                    <div class="swiper-container-next swiper-container__na ">
                                        <img src="../../../static/images/home/swiper-right.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="express-swiper__img" :class="swiperLeft">
                                <swiper class="swiper" :options="swiperOption"
                                ref="Myswiper"
                                @slideChange="changeswiper"
                                v-if="swiperShow">
                                    <swiper-slide v-for="(item,index) in ckList"
                                    :key="index+'p'">
                                    <img :src="item.img" />
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </full-page>
    </div>
</template>
<script>
import '../../../static/css/fullpage.min.css';
// 引入插件
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
export default {
    // components: {
    //     Swiper,
    //     SwiperSlide
    // },
    data(){
        return{
            options:{
                afterRender:this.afterRender,
                navigation:true,
                onLeave:this.onLeave
            },
            expreIndex:0,
            swiperOption:{
                speed:600,
                loop:true,
                nextButton:'.swiper-container-next',
                prevButton:'.swiper-container-prev',
                effect : 'fade',
                simulateTouch : false,
            },
            ckList:[
                {
                    title:'老年人也可以使用的打车平台',
                    id:'01',
                    img:require('../../../static/images/vo/yu-one.png')
                },
                {
                    title:'语音播报，指令交互',
                    id:'02',
                    img:require('../../../static/images/vo/yu-two.png')
                },
                {
                    title:'一键呼叫打车，简化打车步骤',
                    id:'03',
                    img:require('../../../static/images/vo/yu-three.png')
                }
            ],
            ckIndex:0,
            swiperShow:true,
            voiceClassleft:'',
            voiceClassright:'',
            swiperLeft:'',
            swiperRight:''
        }
    },
    computed:{
        swiper(){
            return this.$refs.Myswiper.swiper;
        }
    },
    mounted(){
        this.routerTo(5);
    },
    methods:{
        expreTap(index){
            this.expreIndex = index;
            this.ckIndex = 0;
            this.swiperShow = false;
            setTimeout(()=>{
                this.swiperShow = true;
            },50)
            switch(index){
                case 0:
                  this.ckList = [
                        {
                            title:'老年人也可以使用的打车平台',
                            id:'01',
                            img:require('../../../static/images/vo/yu-one.png')
                        },
                        {
                            title:'语音播报，指令交互',
                            id:'02',
                            img:require('../../../static/images/vo/yu-two.png')
                        },
                        {
                            title:'一键呼叫打车，简化打车步骤',
                            id:'03',
                            img:require('../../../static/images/vo/yu-three.png')
                        }
                    ]
                  break;
                case 1:
                  this.ckList = [
                        {
                            title:'为出租车司机提供线上接单平台',
                            id:'01',
                            img:require('../../../static/images/vo/ds-one.png')
                        },
                        {
                            title:'简便接单流程，快速接单',
                            id:'02',
                            img:require('../../../static/images/vo/ds-two.png')
                        },
                        {
                            title:'巡游，线上接单两不误',
                            id:'03',
                            img:require('../../../static/images/vo/ds-three.png')
                        }
                  ]
                  break;
            }
        },
        afterRender(){

        },
        onLeave(e,n){
            const {index} = n;
            switch(index){
                case 1:
                  this.voiceClassleft = 'animate__animated animate__fadeInLeft';
                  this.voiceClassright = 'animate__animated animate__fadeInRight';
                  break;
                case 2:
                  this.swiperLeft = 'animate__animated animate__fadeInLeft';
                  this.swiperRight = 'animate__animated animate__fadeInRight';
                  break;
            }
        },
        changeswiper(){
            this.ckIndex = this.$refs.Myswiper.$swiper.activeIndex;
        }
    }
}
</script>
<style lang="less" scoped>
@import url('../../../static/css/voice.less');
@import url('../../../static/css/media.less');
</style>